import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { sidebarItems } from "../Sidebar/sidebarItem";
import NotificationList from "../../components/Notification";
import { NotificationHeaderIcon } from "../../assets/icon";
import AvatarIcon from "../../assets/image/AvatarHeader.png";

const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  height: 64px;
  background: #ffffff;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.07);
  display: flex;
  align-items: center;
  padding: 0px 24px;
  border-bottom: 1.5px solid #f8f9fa;
`;

const HeaderTitle = styled.h1`
  color: #171a1f;
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  font-weight: 800;
  margin-left: 3.5rem;
`;

const HeaderUnderline = styled.div`
  flex-grow: 1;
  margin-left: 24px;
  margin-right: auto;
`;

const NotificationIcon = styled.button`
  margin-right: 24px;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
`;

const HeaderAvatar = styled.div`
  width: 44px;
  height: 44px;
  background: #ffd5c3;
  border-radius: 50px;
  overflow: hidden;
  cursor: pointer;
`;

function getActiveTitle(location) {
  const activeItem = sidebarItems.find(
    (item) => item.path === location.pathname
  );
  return activeItem ? activeItem.title : "";
}

const Header = () => {
  const location = useLocation();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const openNotifications = () => setIsNotificationOpen(true);
  const closeNotifications = () => setIsNotificationOpen(false);

  return (
    <HeaderWrapper>
      <NotificationList
        isOpen={isNotificationOpen}
        onClose={closeNotifications}
      />
      <HeaderTitle>{getActiveTitle(location)}</HeaderTitle>
      <HeaderUnderline />
      <NotificationIcon onClick={openNotifications}>
        <NotificationHeaderIcon />
      </NotificationIcon>
      <HeaderAvatar>
        <img
          src={'https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1'}
          alt="Avatar"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </HeaderAvatar>
    </HeaderWrapper>
  );
};

export default Header;
