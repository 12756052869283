import React, { useState, useRef } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { Image, Paperclip } from "lucide-react";
import IconButton from "../IconButton";
import { useWindowSize } from "../../utils";
import AvatarChat from "../../assets/image/AvatarChat.png";
import AvatarChatUser from "../../assets/image/AvatarProfil.png";
import UserMessage from "./UserMessage";
import UserDialogue from "./UserDialogue";
import { SelectAuth } from "../../redux/selectors";
import { useParams } from "react-router-dom";
import ChatWindow from "../ChatWindow";
const ChatContainer = styled.section`
  width: 100%;
  height: ${(props) => props.size - 70}px;
  background: #f8f9fa;
  display: flex;
  flex-direction: column;
`;

const ChatHeader = styled.div`
  height: 96px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const ChatHeaderAvatar = styled.div`
  width: 64px;
  height: 64px;
  background: #ffd5c3;
  border-radius: 32px;
  margin-right: 12px;
`;

const ChatHeaderName = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #171a1f;
  margin: 0;
`;

const ChatBody = styled.div`
  flex-grow: 1;
  background: #f8f9fa;
  overflow-y: auto;
  padding: 20px;
  max-height: 72vh;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MessageContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: ${(props) => (props.isUser ? "flex-end" : "flex-start")};

  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
  opacity: 0;
  animation: ${fadeIn} 300ms ease-out forwards;
  animation-delay: ${(props) => props.index * 100 + 200}ms;
`;

const MessageContent = styled.div`
  background: ${(props) => (props.isUser ? "#f1f2fd" : "#ffffff")};
  border: 1px solid ${(props) => (props.isUser ? "#cacdf8" : "#f3f4f6")};
  border-radius: 4px;
  padding: 11px;
  max-width: 70%;
`;

const MessageText = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #171a1f;
  margin: 0;
`;

const MessageTime = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #6e7787;
  margin-top: 4px;
  display: block;
`;

const ChatInputArea = styled.div`
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  height: 35px;
  background: #f3f4f6;
  border: none;
  border-radius: 4px;
  padding: 0 12px;
  margin: 0 12px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
`;

const SendButton = styled(IconButton)`
  width: 87px;
  height: 34px;
  background: #535ce8;
  color: #ffffff;
  border-radius: 4px;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 9px;
`;

const AvatarIsUser = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 9px;
`;

const ProfileWrapper = styled.section`
  background: #f8f9fa;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);
  padding-right: 16px;
  font-family: "Lato", sans-serif;
  height: 100vh;
`;

const ProfileWrapperDialogue = styled.section`
  background: #f8f9fa;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);
  font-family: "Lato", sans-serif;
  height: 100vh;
`;

function Dialogue() {
  const { user } = SelectAuth();
  const { height } = useWindowSize();
  const [messages, setMessages] = useState();
  const [selectChat, setSelectChat] = useState(
    ["manager"].includes(user?.role) ? true : false
  );
  const [inputMessage, setInputMessage] = useState("");
  const [userMessageName, setUserMessageName] = useState("Антон Букин");
  const fileInputRef = useRef(null);

  const handleSendMessage = () => {
    if (inputMessage.trim() !== "") {
      const newMessage = {
        id: messages.length + 1,
        text: inputMessage,
        isUser: true,
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };
      setMessages([...messages, newMessage]);
      setInputMessage("");
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newMessage = {
          id: messages.length + 1,
          isUser: true,
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          file: {
            name: file.name,
            type: file.type,
            url: e.target.result,
          },
        };
        setMessages([...messages, newMessage]);
      };
      reader.readAsDataURL(file);
    }
  };
  const { id } = useParams();

  return (
    <>
      <ProfileWrapperDialogue>
        {selectChat ? (
          <UserDialogue
            setSelectChat={setSelectChat}
            selectChat={setMessages}
            userNameChat={setUserMessageName}
            managerId={selectChat}
          />
        ) : (
          <ProfileWrapper>
            <UserMessage setSelectChat={setSelectChat} />
          </ProfileWrapper>
        )}
      </ProfileWrapperDialogue>
      <ChatContainer size={height}>
        {id ? (
          <ChatWindow />
        ) : (
          <h1
            style={{
              textAlign: "center",
              paddingTop: "30vh",
            }}
          >
            Пожалуйста, выберите один из чатов!
          </h1>
        )}
        {/* <ChatHeader>
          <ChatHeaderAvatar>
            <img src={AvatarChat} alt="chat" />
          </ChatHeaderAvatar>
          <ChatHeaderName>{userMessageName}</ChatHeaderName>
        </ChatHeader>
        <ChatBody>
          {messages.map((message, index) => (
            <MessageContainer
              key={message.id}
              isUser={message.isUser}
              index={index}
            >
              {!message.isUser && (
                <AvatarIsUser src={AvatarChat} alt="User avatar" />
              )}
              <MessageContent isUser={message.isUser}>
                {message.text && <MessageText>{message.text}</MessageText>}
                {message.file &&
                  (message.file.type.startsWith("image") ? (
                    <img
                      src={message.file.url}
                      alt={message.file.name}
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  ) : (
                    <a href={message.file.url} download={message.file.name}>
                      {message.file.name}
                    </a>
                  ))}
                <MessageTime>{message.time}</MessageTime>
              </MessageContent>
              {message.isUser && (
                <Avatar src={AvatarChatUser} alt="User avatar" />
              )}
            </MessageContainer>
          ))}
        </ChatBody>
        {["owner", "manager"].includes(user?.role) && (
          <ChatInputArea>
            <IconButton onClick={() => fileInputRef.current.click()}>
              <Paperclip size={24} />
            </IconButton>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <IconButton onClick={() => fileInputRef.current.click()}>
              <Image size={24} />
            </IconButton>
            <ChatInput
              placeholder="Type a message..."
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
            />
            <SendButton onClick={handleSendMessage}>Отправить</SendButton>
          </ChatInputArea>
        )} */}
      </ChatContainer>
    </>
  );
}

export default Dialogue;
