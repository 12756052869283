// src/components/TeamTable/TeamTable.jsx
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Plus, SquarePen, ChevronDown, ChevronUp } from "lucide-react";
import Modal from "./Modal";
import ModalEmployee from "./ModalEmployee";
import { SelectAuth } from "../../redux/selectors";
import request from "../../utils/request";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "../../hooks/useWindowWidth"; // Import the custom hook

// Styled Components
const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  font-family: "Lato", sans-serif;
  padding: 3rem;
  overflow-y: auto;
  height: 100vh;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 5rem;
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }
`;

const ActionButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  color: #535ce8;
  background: #e8e9fd;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background 0.2s;

  &:hover {
    background: #d1d3fc;
  }
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  background: #cacdf8;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  color: #535ce8;
  font-weight: 700;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #b8bcf6;
  }

  @media (max-width: 768px) {
    gap: 10px;
    padding: 8px 16px;
    font-size: 14px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  
`;

const LoadingCell = styled.div`
  height: 20px;
  background: #f0f1f2;
  border-radius: 4px;
  animation: pulse 1.5s infinite;

  @keyframes pulse {
    0% {
      opacity: 0.6;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.6;
    }
  }
`;

// Additional Styled Components for Accordion
const AccordionItem = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 16px;
  overflow: hidden;
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const AccordionHeader = styled.div`
  background: #fafafb;
  padding: 15px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const AccordionTitle = styled.div`
  font-weight: 600;
  color: #565e6c;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AccordionContent = styled.div`
  padding: 15px 12px;
  background: #ffffff;
  animation: fadeIn 0.3s ease-in-out;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 15px;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ChevronIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

// TeamTable Component
const TeamTable = () => {
  const { user } = SelectAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEmployee, setIsModalOpenEmployee] = useState(false);
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeAccordion, setActiveAccordion] = useState(null); // For accordion state

  const width = useWindowWidth(); // Get current window width
  const isMobile = width < 768; // Define mobile breakpoint
  const navigate = useNavigate();

  // Fetch teams from the API when the component mounts or modals change
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        setIsLoading(true);
        const response = await request.get("/teams"); // API call to fetch teams
        setTeams(response.data);
      } catch (error) {
        console.error("Error fetching teams:", error);
        // Optionally, handle error state here
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeams();
  }, [isModalOpen, isModalOpenEmployee]);

  // Handle adding a new team (open the modal)
  const handleAddTeam = () => {
    setIsModalOpen(true);
  };

  // Handle adding an employee to a specific team
  const handleAddEmployee = (teamNumber) => {
    setIsModalOpenEmployee(true);
    navigate("/command?id=" + teamNumber);
  };

  // Toggle accordion
  const toggleAccordion = (teamId) => {
    setActiveAccordion(activeAccordion === teamId ? null : teamId);
  };

  return (
    <MainContainer>
      {["owner"].includes(user?.role) && (
        <ButtonContainer>
          <AddButton onClick={handleAddTeam}>
            Команда <Plus size={16} />
          </AddButton>
        </ButtonContainer>
      )}

      {isMobile ? (
        // Render Accordion for Mobile
        isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <AccordionItem key={index}>
              <AccordionHeader>
                <LoadingCell style={{ width: "30px" }} />
                <ChevronIcon>
                  <LoadingCell style={{ width: "20px", height: "20px" }} />
                </ChevronIcon>
              </AccordionHeader>
              <AccordionContent>
                <LoadingCell style={{ width: "100%" }} />
                <LoadingCell style={{ width: "100%" }} />
                <LoadingCell style={{ width: "100%" }} />
              </AccordionContent>
            </AccordionItem>
          ))
        ) : (
          teams.map((team) => (
            <AccordionItem key={team.id}>
              <AccordionHeader onClick={() => toggleAccordion(team.id)}>
                <AccordionTitle>
                  #{team.id} - {team.name}
                </AccordionTitle>
                <ChevronIcon>
                  {activeAccordion === team.id ? (
                    <ChevronUp size={20} />
                  ) : (
                    <ChevronDown size={20} />
                  )}
                </ChevronIcon>
              </AccordionHeader>
              {activeAccordion === team.id && (
                <AccordionContent>
                  <strong>Сотрудники:</strong> {team.quantity}
                  <strong>Лид:</strong> {team.lead || "Отсутствует"}
                  <ActionButton onClick={() => handleAddEmployee(team.id)}>
                    Сотрудник <Plus size={16} />
                  </ActionButton>
                </AccordionContent>
              )}
            </AccordionItem>
          ))
        )
      ) : (
        // Render Table for Desktop
        <Table>
          <thead>
            <tr>
              <Th>Номер</Th>
              <Th>Команда</Th>
              <Th>Сотрудники</Th>
              <Th>Лид</Th>
              <Th></Th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: 3 }).map((_, index) => (
                  <Tr key={index}>
                    <Td>
                      <LoadingCell style={{ width: "30px" }} />
                    </Td>
                    <Td>
                      <LoadingCell style={{ width: "100px" }} />
                    </Td>
                    <Td>
                      <LoadingCell style={{ width: "30px" }} />
                    </Td>
                    <Td>
                      <LoadingCell style={{ width: "120px" }} />
                    </Td>
                    <Td>
                      <LoadingCell style={{ width: "60px" }} />
                    </Td>
                  </Tr>
                ))
              : teams.map((team) => (
                  <Tr key={team.id}>
                    <Td>{team.id}</Td>
                    <Td>
                      {team.name}
                      <SquarePen
                        color="#379AE6"
                        cursor="pointer"
                        size={22}
                        onClick={handleAddTeam}
                        style={{ float: "right" }}
                        aria-label={`Edit team ${team.name}`}
                      />
                    </Td>
                    <Td>{team.quantity}</Td>
                    <Td>{team.lead || "Отсутствует"}</Td>
                    <Td>
                      <ActionButton onClick={() => handleAddEmployee(team.id)}>
                        Сотрудник <Plus size={16} />
                      </ActionButton>
                    </Td>
                  </Tr>
                ))}
          </tbody>
        </Table>
      )}

      {/* Modals */}
      <Modal onClose={() => setIsModalOpen(false)} isOpen={isModalOpen} />
      <ModalEmployee
        onClose={() => setIsModalOpenEmployee(false)}
        isOpen={isModalOpenEmployee}
      />
    </MainContainer>
  );
};

export default TeamTable;
