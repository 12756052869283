import React from "react";
import axios from "axios";
import { FaMicrophone, FaStop } from "react-icons/fa"; // Make sure to install react-icons
import { IoSend, IoTrash } from "react-icons/io5"; // Send icon
import { ReactMediaRecorder } from "react-media-recorder";
import { base_url } from "../../utils/request";
import "./oggStyles.css";

export const OggRecorder = ({ chat_id, accountId, fetchLatestMessages }) => {
  const sendAudioFile = async (audioBlob) => {
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("chat_id", chat_id);
      formData.append("account_id", accountId);
      formData.append("file", audioBlob, `${new Date().toISOString()}.wav`);

      // Send the audio file to the server
      await axios.post(`${base_url}/chat/message/send`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Token for authentication
        },
      });

      // After sending the file, fetch the latest messages
      fetchLatestMessages();
    } catch (error) {
      console.error("Error sending audio file:", error);
    }
  };

  const handleSendAudio = async (mediaBlobUrl, clearBlobUrl) => {
    try {
      // Fetch the audio file from the mediaBlobUrl
      const audioBlob = await fetch(mediaBlobUrl).then((r) => r.blob());
      sendAudioFile(audioBlob); // Send the fetched blob to the API
      clearBlobUrl();
    } catch (error) {
      console.error("Error fetching and sending audio file:", error);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 5, padding: 8 }}>
      <ReactMediaRecorder
        audio
        mimeType="audio/ogg"
        render={({
          status,
          startRecording,
          stopRecording,
          mediaBlobUrl,
          clearBlobUrl,
        }) => (
          <>
            {/* Recording controls */}
            <p className="record-button">
              {status === "recording" ? (
                <>
                  <FaStop
                    className="icon"
                    style={{ marginRight: "8px" }}
                    onClick={() => {
                      stopRecording(); // Stop the recording
                    }}
                  />
                  <span></span>
                </>
              ) : (
                <>
                  <FaMicrophone
                    className="icon"
                    style={{ marginRight: "8px" }}
                    onClick={startRecording}
                  />
                  <span></span>
                </>
              )}
            </p>
            {status === "recording" && (
              <div className="recording-indicator">
                <span
                  className="pulse-circle"
                  style={{
                    display: "inline-block",
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: "red",
                    animation: "pulse 1s infinite",
                  }}
                ></span>
                <span style={{ marginLeft: "8px" }}>Recording...</span>
              </div>
            )}

            {/* Playback and sending recorded audio */}
            {mediaBlobUrl && (
              <>
                <audio controls src={mediaBlobUrl} />
                {/* Immediately send the audio when the recording stops */}
                <p onClick={clearBlobUrl}>
                  <IoTrash className="icon" style={{ marginLeft: "8px" }} />
                </p>
                <p
                  onClick={() => {
                    handleSendAudio(mediaBlobUrl, clearBlobUrl); // Fetch the blob from the URL and send it
                  }}
                >
                  <IoSend className="icon" style={{ marginLeft: "8px" }} />
                </p>
              </>
            )}
          </>
        )}
      />
    </div>
  );
};
