import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { X, Calendar, PlusCircle, Trash2, FileText, Image } from "lucide-react";
import axios from "axios";
import { base_url } from "../../utils/request";
import request from "../../utils/request";
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 26, 31, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const ModalContainer = styled.div`
  width: 560px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: scale(${(props) => (props.isOpen ? 1 : 0.9)});
  @media (max-width: 768px) {
    width: 90%;
    margin: 0px auto;
    border-radius: 0px;
    padding: 4px;
  }
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  border-bottom: 1px solid #e5e7eb;
`;

const ModalTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #111;
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  &:hover {
    color: #111;
  }
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 6px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  background: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 0 12px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #4a7dff;
    box-shadow: 0 0 0 2px rgba(74, 125, 255, 0.2);
  }
`;

const Select = styled.select`
  width: 100%;
  height: 40px;
  background: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 0 12px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #111827;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236B7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;

  &:focus {
    outline: none;
    border-color: #4a7dff;
    box-shadow: 0 0 0 2px rgba(74, 125, 255, 0.2);
  }
`;

const Button = styled.button`
  background: #4f46e5;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  max-width: 10rem;
  float: right;

  &:hover {
    background-color: #4338ca;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.4);
  }
`;

const DistributionItem = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;

const DistributionSelect = styled(Select)`
  flex: 1;
`;

const DistributionInput = styled(Input)`
  width: 80px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ef4444;
  display: flex;
  align-items: center;
`;

const AddButton = styled(Button)`
  background: none;
  color: #4f46e5;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  max-width: 179px;
  &:hover {
    background: none;
    color: #4338ca;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  background: #4f46e5;
  color: #ffffff;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  max-width: 178px;

  &:hover {
    background-color: #4338ca;
  }
`;

const ErrorMessage = styled.span`
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const TextWraper = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: 18px;
  margin-top: 4px;
  margin-bottom: 14px;
  display: block;
`;

const FormFieldAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: end;
`;

const ContentWraper = styled.div`
  padding: 24px;

  max-height: calc(100vh - 200px);
  overflow-y: auto;
  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const DriverHr = styled.div`
  border-bottom: 1px solid #e5e7eb;
`;

const Modal = ({ isOpen, onClose, isEditModal, warehouseData, editId }) => {
  const [receipt, setReceipt] = useState(null);
  const [file, setFile] = useState(null);
  const [offers, setOffers] = useState([]);
  const [distribute, setDistribute] = useState([]);
  const receiptInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      category: "",
      supplier: "",
      quantity: "",
      amount: "",
      currency: "USD $",
      date: "2024-07-22",
      comment: "",
      distributions: [{ master: "", quantity: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "distributions",
  });

  useEffect(() => {
    if (warehouseData) {
      reset(warehouseData);
    }
  }, [warehouseData]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await request.get("/category-expenses"); // Replace with your actual API endpoint
        setOffers(response?.data);
        const dist = await request.get("/users"); // Replace with your actual API endpoint
        setDistribute(dist?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
      }
    };

    fetchCategories();
  }, []);
  const handleFileUpload = (event, fileType) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (fileType === "receipt") {
          setReceipt(selectedFile);
        } else {
          setFile(selectedFile);
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const onSubmit = async (data) => {
    const token = localStorage.getItem("token");
    const formData = new FormData();

    // Append form fields
    formData.append("title", data.name);
    formData.append("category_expense_id", data.category);
    formData.append("supplier", data.supplier);
    formData.append("quantity", data.quantity);
    formData.append("price", data.amount);
    formData.append("currency", data.currency);
    formData.append("date", data.date);
    formData.append("comment", data.comment);

    // Append distributions array
    data.distributions.forEach((distribution, index) => {
      formData.append(`distribute`, distribution.master);
      formData.append(`quantity2`, distribution.quantity);
    });

    // Append files
    if (receipt) {
      formData.append("add_check", receipt);
    }
    if (file) {
      formData.append("file", file);
    }

    // Send request
    const url = isEditModal
      ? base_url + `/base-expenseUpdate/${editId}`
      : base_url + "/base-expenses";
    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      onClose(); // Close modal after successful submission
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer isOpen={isOpen}>
        <ModalHeader>
          <ModalTitle>
            {isEditModal ? "Редактировать расходник" : "Добавить расходник"}
          </ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={24} />
          </CloseButton>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContentWraper>
            <FormField>
              <Label>Название</Label>
              <Controller
                name="name"
                control={control}
                rules={{ required: "Обязательное поле" }}
                render={({ field }) => <Input {...field} />}
              />
              {errors.name && (
                <ErrorMessage>{errors.name.message}</ErrorMessage>
              )}
            </FormField>

            <FormField>
              <Label>Категория</Label>
              <Controller
                name="category"
                control={control}
                rules={{ required: "Обязательное поле" }}
                render={({ field }) => (
                  <Select {...field}>
                    <option value="">Выберите категорию</option>
                    {offers?.map((item, i) => (
                      <option key={i} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </Select>
                )}
              />
              {errors.category && (
                <ErrorMessage>{errors.category.message}</ErrorMessage>
              )}
            </FormField>

            <FormField>
              <Label>Поставщик</Label>
              <Controller
                name="supplier"
                control={control}
                rules={{ required: "Обязательное поле" }}
                render={({ field }) => <Input {...field} />}
              />
              {errors.supplier && (
                <ErrorMessage>{errors.supplier.message}</ErrorMessage>
              )}
            </FormField>

            <FormField>
              <Label>Количество</Label>
              <Controller
                name="quantity"
                control={control}
                rules={{ required: "Обязательное поле" }}
                render={({ field }) => <Input {...field} type="number" />}
              />
              {errors.quantity && (
                <ErrorMessage>{errors.quantity.message}</ErrorMessage>
              )}
            </FormField>

            <FormField>
              <Label>Сумма</Label>
              <div style={{ display: "flex", gap: "12px" }}>
                <div style={{ flex: 1 }}>
                  <Controller
                    name="amount"
                    control={control}
                    rules={{ required: "Обязательное поле" }}
                    render={({ field }) => <Input {...field} type="number" />}
                  />
                  {errors.amount && (
                    <ErrorMessage>{errors.amount.message}</ErrorMessage>
                  )}
                </div>
                <div style={{ width: "120px" }}>
                  <Controller
                    name="currency"
                    control={control}
                    render={({ field }) => (
                      <Select {...field}>
                        <option value="USD $">USD $</option>
                        <option value="EUR €">EUR €</option>
                        <option value="RUB ₽">RUB ₽</option>
                      </Select>
                    )}
                  />
                </div>
              </div>
            </FormField>

            <FormField>
              <Label>Дата создания</Label>
              <Controller
                name="date"
                control={control}
                rules={{ required: "Обязательное поле" }}
                render={({ field }) => (
                  <div style={{ position: "relative" }}>
                    <Input
                      {...field}
                      type="date"
                      style={{ paddingLeft: "40px" }}
                    />
                    <Calendar
                      style={{
                        position: "absolute",
                        left: "12px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      size={20}
                      color="#6B7280"
                    />
                  </div>
                )}
              />
              {errors.date && (
                <ErrorMessage>{errors.date.message}</ErrorMessage>
              )}
            </FormField>

            <FormField>
              <Label>Комментарий</Label>
              <Controller
                name="comment"
                control={control}
                render={({ field }) => <Input {...field} rows={3} />}
              />
            </FormField>

            <FormField>
              <FormFieldAdd>
                <TextWraper>Распределить</TextWraper>
                <AddButton
                  type="button"
                  onClick={() => append({ master: "", quantity: "" })}
                >
                  <PlusCircle size={22} />
                </AddButton>
              </FormFieldAdd>
              {fields.map((field, index) => (
                <DistributionItem key={field.id}>
                  <Controller
                    name={`distributions.${index}.master`}
                    control={control}
                    render={({ field }) => (
                      <DistributionSelect {...field}>
                        {distribute?.map((item, i) => (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </DistributionSelect>
                    )}
                  />
                  <Controller
                    name={`distributions.${index}.quantity`}
                    control={control}
                    render={({ field }) => (
                      <DistributionInput
                        {...field}
                        type="number"
                        placeholder="Кол-во"
                      />
                    )}
                  />
                  <DeleteButton type="button" onClick={() => remove(index)}>
                    <Trash2 size={20} />
                  </DeleteButton>
                </DistributionItem>
              ))}
            </FormField>

            <div style={{ display: "flex", gap: "12px", marginTop: "24px" }}>
              <FileInput
                type="file"
                accept="image/*"
                ref={receiptInputRef}
                onChange={(e) => handleFileUpload(e, "receipt")}
                id="receipt-upload"
              />
              <FileLabel htmlFor="receipt-upload" style={{ flex: 1 }}>
                <FileText size={16} style={{ marginRight: "8px" }} />
                {receipt ? receipt.name : "Добавить чек"}
              </FileLabel>

              <FileInput
                type="file"
                ref={fileInputRef}
                onChange={(e) => handleFileUpload(e, "file")}
                id="file-upload"
              />
              <FileLabel htmlFor="file-upload" style={{ flex: 1 }}>
                <Image size={16} style={{ marginRight: "8px" }} />
                {file ? file.name : "Добавить файл"}
              </FileLabel>
            </div>
          </ContentWraper>
          <DriverHr />
          <ModalFooter>
            <Button type="submit" style={{ width: "100%" }}>
              Добавить
            </Button>
          </ModalFooter>
        </form>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
