import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { X, PlusCircle, Trash2, Calendar } from "lucide-react";
import request from "../../utils/request";
import toast from "react-hot-toast";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 26, 31, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const ModalContainer = styled.div`
  width: 560px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: scale(${(props) => (props.isOpen ? 1 : 0.9)});
  @media (max-width: 768px) {
    width: 90%;
    margin: 0px auto;
    border-radius: 0px;
    padding: 4px;
  }
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
`;

const ModalTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #111;
  margin: 0;
`;

const CloseButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #666;
  background-color: white;
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 1px;
  background-color: #dee1e6;
  margin: 0;
`;

const FormContent = styled.div`
  padding: 24px;
  overflow-y: scroll;
  max-height: 600px;
  scrollbar-width: none;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 6px;
`;

const inputStyles = css`
  width: 100%;
  height: 40px;
  background: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 0 12px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #4a7dff;
  }
`;

const Input = styled.input`
  ${inputStyles}
`;

const Select = styled.select`
  ${inputStyles}
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  color: #535ce8;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  margin-bottom: 16px;
`;

const ExpenseItem = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
`;

const ExpenseSelect = styled(Select)`
  flex: 1;
`;

const ExpenseInput = styled(Input)`
  width: 80px;
`;

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #9ca3af;
  display: flex;
  align-items: center;
`;

const SubmitButton = styled.button`
  width: 120px;
  height: 40px;
  background: #535ce8;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 24px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: end;
`;

const ErrorMessage = styled.span`
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const FormFieldAdd = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextWraper = styled.div`
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 14px;
  display: block;
`;

const Modal = ({ onClose, isOpen, title, taskId }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      created_at: "2024-07-22",
      offer_id: "",
      title: "",
      comment: "",
      fields: [{ category_id: "", base_expense_id: "", count: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "fields",
  });
  const [offers, setOffers] = useState([]);
  const [distribute, setDistribute] = useState([]);
  const [offerId, setOfferId] = useState([]);
  const [link, setlink] = useState([]);

  // Fetch link from the API when the component mounts
  useEffect(() => {
    const fetchlink = async () => {
      try {
        const response = await request.get("/links");
        setlink(response.data);
      } catch (error) {
        console.error("Error fetching link:", error);
      } finally {
      }
    };

    fetchlink();
  }, []);
  // Fetch existing data if in edit mode
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await request.get("/category-expenses"); // Replace with your actual API endpoint
        setOffers(response?.data);
        const dist = await request.get("/base-expenses"); // Replace with your actual API endpoint
        setDistribute(dist?.data);
        const offer = await request.get("/offers"); // Replace with your actual API endpoint
        setOfferId(offer?.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
      }
    };

    fetchCategories();
  }, []);
  useEffect(() => {
    if (taskId && isOpen) {
      setLoading(true);
      const fetchTaskData = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await request.get(`/flood/${taskId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const taskData = response.data;
          // Assuming the response includes `expenses` and other data
          reset({
            created_at: taskData.created_at,
            offer_id: taskData.offer_id,
            title: taskData.title,
            comment: taskData.comment,
            fields: taskData.expenses || [
              { category_id: "", base_expense_id: "", count: "" },
            ],
          });
          setLoading(false);
        } catch (error) {
          console.error("Error fetching task data:", error);
          setLoading(false);
        }
      };
      fetchTaskData();
    }
  }, [taskId, isOpen, reset]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const token = localStorage.getItem("token");
      if (taskId) {
        // Update existing task
        await request.post(`/flood/update/${taskId}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success("Task updated successfully!");
      } else {
        // Create new task
        await request.post("/flood/create", data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success("Task created successfully!");
      }
      setIsSubmitting(false);
      reset({
        created_at: "",
        offer_id: "",
        title: "",
        comment: "",
        fields: [{ category_id: "", base_expense_id: "", count: "" }],
      });
      onClose();
    } catch (error) {
      console.error("Error submitting task:", error);
      setIsSubmitting(false);
    }
  };

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer isOpen={isOpen}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={20} />
          </CloseButton>
        </ModalHeader>
        <HorizontalLine />
        <FormContent>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormField>
                <Label>Дата создания</Label>
                <Controller
                  name="created_at"
                  control={control}
                  rules={{ required: "Обязательное поле" }}
                  render={({ field }) => (
                    <div style={{ position: "relative" }}>
                      <Input
                        {...field}
                        type="date"
                        style={{ paddingLeft: "40px" }}
                      />
                      <Calendar
                        style={{
                          position: "absolute",
                          left: "12px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        size={20}
                        color="#6B7280"
                      />
                    </div>
                  )}
                />
                {errors.created_at && (
                  <ErrorMessage>{errors.created_at.message}</ErrorMessage>
                )}
              </FormField>

              <FormField>
                <Label>Оффер</Label>
                <Controller
                  name="offer_id"
                  control={control}
                  rules={{ required: "Это поле обязательно" }}
                  render={({ field }) => (
                    <Select {...field}>
                      <option value="">Выберите оффер</option>
                      {offerId?.map((item, i) => (
                        <option key={i} value={item?.id}>
                          {item?.offer_name}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                {errors.offer_id && (
                  <ErrorMessage>{errors.offer_id.message}</ErrorMessage>
                )}
              </FormField>

              <FormField>
                <Label>Название</Label>
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: "Это поле обязательно" }}
                  render={({ field }) => <Input {...field} />}
                />
                {errors.title && (
                  <ErrorMessage>{errors.title.message}</ErrorMessage>
                )}
              </FormField>

              <FormField>
                <Label>Комментарий</Label>
                <Controller
                  name="comment"
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
              </FormField>
              <FormField>
                <Label>Link</Label>
                <Controller
                  name="link_id"
                  control={control}
                  rules={{ required: "Это поле обязательно" }}
                  render={({ field }) => (
                    <Select {...field}>
                      <option value="">Выберите Link</option>
                      {link?.map((item, i) => (
                        <option key={i} value={item?.id}>
                          {item?.link}
                        </option>
                      ))}
                    </Select>
                  )}
                />
                {errors.link_id && (
                  <ErrorMessage>{errors.link_id.message}</ErrorMessage>
                )}
              </FormField>

              <FormFieldAdd>
                <TextWraper>Добавить расходник</TextWraper>
                <AddButton
                  type="button"
                  onClick={() =>
                    append({ category: "", item: "", quantity: "" })
                  }
                >
                  <PlusCircle size={22} />
                </AddButton>
              </FormFieldAdd>

              {fields.map((field, index) => (
                <ExpenseItem key={field.id}>
                  <Controller
                    name={`fields.${index}.category_id`}
                    control={control}
                    rules={{ required: "Обязательно" }}
                    render={({ field }) => (
                      <ExpenseSelect {...field}>
                        <option value=""></option>
                        {offers?.map((item, i) => (
                          <option key={i} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </ExpenseSelect>
                    )}
                  />
                  <Controller
                    name={`fields.${index}.base_expense_id`}
                    control={control}
                    rules={{ required: "Обязательно" }}
                    render={({ field }) => (
                      <ExpenseSelect {...field}>
                        <option value=""></option>
                        {distribute?.map((item, i) => (
                          <option key={i} value={item?.id}>
                            {item?.title}
                          </option>
                        ))}
                      </ExpenseSelect>
                    )}
                  />
                  <Controller
                    name={`fields.${index}.count`}
                    control={control}
                    rules={{
                      required: "Обязательно",
                      min: { value: 1, message: "Минимум 1" },
                    }}
                    render={({ field }) => (
                      <ExpenseInput
                        type="number"
                        placeholder="Кол-во"
                        {...field}
                      />
                    )}
                  />
                  <DeleteButton type="button" onClick={() => remove(index)}>
                    <Trash2 size={20} />
                  </DeleteButton>
                </ExpenseItem>
              ))}
              {errors.fields && (
                <ErrorMessage>Все поля расходников обязательны</ErrorMessage>
              )}
            </form>
          )}
        </FormContent>
        <HorizontalLine />
        <ModalFooter>
          <SubmitButton
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Загрузка..." : "Добавить"}
          </SubmitButton>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
