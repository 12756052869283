import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Paperclip } from "lucide-react";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request from "../../utils/request";

const MainContainer = styled.div`
  overflow-y: scroll;
  height: ${(props) => props.size - 100}px;
  padding-bottom: 3rem;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const PinnedHeader = styled.span`
  margin-left: 24px;
  margin-top: 20px;
  color: #171a1f;
  font-family: Outfit, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  display: block;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationCard = styled.div`
  width: 364px;
  height: 123px;
  margin: 16px 0 0 16px;
  background: ${(props) => (props.isSelected ? "#F3F4F6" : "#ffffff")};
  border: 0.1px solid ${(props) => (props.isSelected ? "#65d46a78" : "#ffffff")};
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
  opacity: 0;
  animation: ${fadeIn} 400ms ease-out forwards;
  animation-delay: ${(props) => props.index * 100 + 200}ms;

  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
`;

const NotificationContent = styled.div`
  padding: 16px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${(props) => props.color || "#f3f4f6"};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f494a;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

const UserName = styled.span`
  color: #323842;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-left: 16px;
`;

const ReplyText = styled.span`
  color: #6e7787;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 600;
`;

const TimeStamp = styled.span`
  color: #9095a0;
  font-family: Lato, sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
`;

const MessagePreview = styled.div`
  color: #171a1f;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-top: 16px;
`;

const EmailPreview = styled.div`
  color: #323842;
  font-family: Lato, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
`;

const StatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #2acccf;
  margin-left: 8px;
`;

const FileIcon = styled(Paperclip)`
  margin-left: 8px;
  color: #9095a0;
`;

const UserMessage = ({ setSelectChat }) => {
  const { user } = SelectAuth();
  const { height } = useWindowSize();
  const [selectedCard, setSelectedCard] = useState(null);
  const [users, setUsers] = useState([]);

  const notifications = [
    {
      id: 1,
      user: "JO",
      userName: "Хавьер Ортис",
      time: "45s",
      message: "Новый отчет готов",
      email: "Я загрузил ежемесячный отчет...",
      avatarColor: "#2acccf",
      hasAttachment: true,
      messages: [
        {
          id: 1,
          text: "Привет! Как продвигается работа над отчетом?",
          isUser: false,
          time: "10:00 AM",
        },
        {
          id: 2,
          text: "Здравствуйте! Отчет почти готов, заканчиваю последние правки.",
          isUser: true,
          time: "10:05 AM",
        },
        {
          id: 3,
          text: "Отлично! Когда планируете отправить?",
          isUser: false,
          time: "10:07 AM",
        },
        {
          id: 4,
          text: "Думаю, смогу отправить его вам до конца дня.",
          isUser: true,
          time: "10:10 AM",
        },
        {
          id: 5,
          text: "Замечательно, буду ждать. Спасибо за оперативность!",
          isUser: false,
          time: "10:12 AM",
        },
      ],
    },
    {
      id: 2,
      user: "АК",
      userName: "Анна Ковалева",
      time: "2m",
      message: "Вопрос по проекту",
      email: "Можем ли мы обсудить сроки...",
      avatarColor: "#faedbd",
    },
    {
      id: 3,
      user: "ИС",
      userName: "Игорь Смирнов",
      time: "10m",
      message: "Обновление статуса",
      email: "Задача #1234 завершена...",
      avatarColor: "#d7c8f0",
      hasAttachment: false,
    },
    {
      id: 4,
      user: "ЕП",
      userName: "Елена Петрова",
      time: "1h",
      message: "Приглашение на встречу",
      email: "Приглашаю вас на совещание...",
      avatarColor: "#f3d2c1",
    },
    {
      id: 5,
      user: "МВ",
      userName: "Максим Волков",
      time: "2h",
      message: "Новый клиент",
      email: "У нас появился новый клиент...",
      avatarColor: "#8ed1fc",
      hasAttachment: true,
    },
    {
      id: 6,
      user: "ОН",
      userName: "Ольга Новикова",
      time: "3h",
      message: "Вопрос по бюджету",
      email: "Нужно обсудить распределение...",
      avatarColor: "#d4eafa",
    },
    {
      id: 7,
      user: "ДК",
      userName: "Дмитрий Козлов",
      time: "5h",
      message: "Обновление системы",
      email: "Планируется обновление...",
      avatarColor: "#ffd5cd",
      hasAttachment: true,
    },
  ];

  const handleCardClick = (id) => {
    setSelectedCard((prevSelected) => (prevSelected === id ? '' : id));
  };

  const fetchUsers = async () => {
    try {
      const response = await request.get("/users"); // Fetch users from the backend API
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Fetch users from API
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <PinnedHeader>Обработчики</PinnedHeader>
      <MainContainer size={height}>
        {users?.map(
          (notification, index) =>
            notification.role === "manager" && (
              <NotificationCard
                key={notification?.id}
                index={index}
                isSelected={selectedCard === notification?.id}
                onClick={() => {
                  setSelectChat(notification?.id);
                  handleCardClick(notification?.id);
                }}
              >
                <NotificationContent>
                  <UserInfo>
                    <Avatar
                      color={notification?.avatarColor}
                      src={
                        notification?.image ||
                        "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
                      }
                    ></Avatar>
                    <UserName>
                      {notification?.name} <ReplyText>ответил вам</ReplyText>
                    </UserName>
                    <TimeStamp>{notification?.time}</TimeStamp>
                    <StatusCircle />
                    {notification?.hasAttachment && <FileIcon size={16} />}
                  </UserInfo>
                  <MessagePreview>{notification?.phone}</MessagePreview>
                  <EmailPreview>{notification?.email}</EmailPreview>
                </NotificationContent>
              </NotificationCard>
            )
        )}
      </MainContainer>
    </>
  );
};

export default UserMessage;
