import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { X } from "lucide-react";
import { useLocation } from "react-router-dom";
import request from "../../utils/request";
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(23, 26, 31, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

const ModalContainer = styled.div`
  width: 560px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transform: scale(${(props) => (props.isOpen ? 1 : 0.9)});
  @media (max-width: 768px) {
    width: 90%;
    margin: 0px auto;
    border-radius: 0px;
    padding: 4px;
  }
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
`;

const ModalTitle = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #111;
  margin: 0;
`;

const CloseButton = styled.button`
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #666;
  background-color: white;
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 1px;
  background-color: #dee1e6;
  margin: 0;
`;

const FormContent = styled.div`
  padding: 24px;
  overflow-y: scroll;
  max-height: 600px;
  scrollbar-width: none;
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 6px;
`;

const inputStyles = css`
  width: 100%;
  height: 40px;
  background: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 0 12px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #111827;

  &:focus {
    outline: none;
    border-color: #4a7dff;
  }
`;

const Input = styled.input`
  ${inputStyles}
`;

const Select = styled.select`
  ${inputStyles}
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px top 50%;
  background-size: 12px auto;
`;

const SubmitButton = styled.button`
  width: 120px;
  height: 40px;
  background: #535ce8;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 24px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ModalFooter = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: end;
`;

const ErrorMessage = styled.span`
  color: #ef4444;
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const ModalEmployee = ({ onClose, isOpen }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employees, setEmployees] = useState([]); // State to store fetched employees
  const [team, setTeam] = useState(null); // State to store current team data

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      employeeId: "",
    },
  });

  // Extract team ID from the URL query parameters
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const teamId = searchParams.get("id");

  // Fetch employees and team data when the modal is opened
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all employees
        const employeesResponse = await request.get("/users");
        setEmployees(employeesResponse.data);

        // Fetch the current team data
        const teamResponse = await request.get(`/teams/${teamId}`);
        setTeam(teamResponse.data); // Store the current team details
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen, teamId]);

  // Handle form submission to add an employee to the cloned team
  const onSubmit = async (data) => {
    if (!team) return; // Exit if team data hasn't been fetched yet

    setIsSubmitting(true);
    try {
      // Clone the team and add the new employee to the current list of employees
      const updatedTeam = {
        ...team,
        user_ids: [...team.users.map((item) => item.id), data.employeeId ],
      };

      // POST request to update the team with the new employee
      await request.put(`/teams/${teamId}`, updatedTeam);

      // Close modal after successful submission
      onClose();
    } catch (error) {
      console.error("Error updating team:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer isOpen={isOpen}>
        <ModalHeader>
          <ModalTitle>Добавить сотрудника</ModalTitle>
          <CloseButton onClick={onClose}>
            <X size={20} />
          </CloseButton>
        </ModalHeader>
        <HorizontalLine />
        <FormContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormField>
              <Label>Выбрать сотрудника</Label>
              <Controller
                name="employeeId"
                control={control}
                rules={{ required: "Обязательное поле" }}
                render={({ field }) => (
                  <Select {...field}>
                    <option value="">Выберите сотрудника</option>
                    {employees.map((employee) => (
                      <option key={employee.id} value={employee.id}>
                        {employee.name} {employee.surname}
                      </option>
                    ))}
                  </Select>
                )}
              />
              {errors.employeeId && (
                <ErrorMessage>{errors.employeeId.message}</ErrorMessage>
              )}
            </FormField>
          </form>
        </FormContent>
        <HorizontalLine />
        <ModalFooter>
          <SubmitButton
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Загрузка..." : "Добавить"}
          </SubmitButton>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ModalEmployee;
