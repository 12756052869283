import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Modal from "./Modal";
import request from "../../utils/request"; // Import axios request module

// Styled Components

const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(18, 15, 40, 0.12);
  font-family: "Lato", sans-serif;
`;

const Title = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: #171a1f;
  margin: 32px 0 20px 72px;

  @media (max-width: 768px) {
    margin: 20px 16px;
    font-size: 24px;
  }
`;

const Table = styled.table`
  width: 1306px;
  margin: 20px 0 0 72px;
  border-collapse: collapse;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #f3f4f6;
  }
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
  background: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};

  @media (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
  }
`;

const ContainerWrapperLink = styled.div`
  overflow-y: scroll;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
`;

const LoaderTd = styled.td`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #379ae6;

  @media (max-width: 768px) {
    padding: 20px 16px;
  }
`;

const Tr = styled.tr`
  &:hover {
    background-color: #fff4f0;
  }

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 10px;
    background-color: #ffffff;
    border: 1px solid #f3f4f6;
    border-radius: 8px;
    padding: 10px;
  }
`;

// Accordion Styled Components

const AccordionItem = styled.div`
  margin-bottom: 16px;
`;

const AccordionHeader = styled.div`
  padding: 15px 16px;
  cursor: pointer;
  background-color: #fafafb;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 769px) {
    display: none;
  }
`;

const AccordionContent = styled.div`
  padding: 16px;
  display: ${(props) => (props.isOpen ? "grid" : "none")};
  gap: 10px; 
  background: #fafafb;
  @media (min-width: 769px) {
    display: none;
  }
`;

const AccordionButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
  background: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};
  margin-top: 10px;
  width: 48%;

  @media (min-width: 769px) {
    margin-top: 0;
    width: auto;
  }
`;

// Main Component

const MyLinktable = () => {
  const [offers, setOffers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openAccordions, setOpenAccordions] = useState({});

  // Fetch offers from the API when the component mounts
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        setIsLoading(true);
        const response = await request.get("/links"); // Adjust endpoint
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching offers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOffers();
  }, [isModalOpen]);

  // Delete an offer via API
  const deleteOffer = async (index) => {
    try {
      const offerToDelete = offers[index];
      await request.delete(`/links/${offerToDelete.id}`); // Adjust endpoint
      setOffers(offers.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting offer:", error);
    }
  };

  // Edit offer
  const editOffer = (index) => {
    setEditIndex(index);
    setIsModalOpen(true); // Open the modal for editing
  };

  // Toggle accordion
  const toggleAccordion = (id) => {
    setOpenAccordions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <MainContainer>
      <Modal
        editId={editIndex}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <ContainerWrapperLink>
        {/* Table for Desktop */}
        <Table>
          <thead>
            <tr>
              <Th>ID</Th>
              <Th>Площадка</Th>
              <Th>Ссылка</Th>
              <Th>Действия</Th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <LoaderTd colSpan="4">Загрузка...</LoaderTd>
              </tr>
            ) : (
              offers.map((offer, index) => (
                <Tr key={offer.id}>
                  <Td>#{offer.id}</Td>
                  <Td>{offer.platform}</Td>
                  <Td>
                    <a href={offer.link} target="_blank" rel="noopener noreferrer">
                      {offer.link}
                    </a>
                  </Td>
                  <Td>
                    <div style={{ display: "flex" }}>
                      <ActionButton edit onClick={() => editOffer(index)}>
                        Редактировать
                      </ActionButton>
                      <ActionButton onClick={() => deleteOffer(index)}>
                        Удалить
                      </ActionButton>
                    </div>
                  </Td>
                </Tr>
              ))
            )}
          </tbody>
        </Table>

        {/* Accordion for Mobile */}
        {!isLoading && offers.length > 0 && (
          <div>
            {offers.map((offer, index) => (
              <AccordionItem key={offer.id}>
                <AccordionHeader onClick={() => toggleAccordion(offer.id)}>
                  <span>#{offer.id} - {offer.platform}</span>
                  <span>{openAccordions[offer.id] ? "-" : "+"}</span>
                </AccordionHeader>
                <AccordionContent isOpen={openAccordions[offer.id]}>
                  <p><strong>Ссылка:</strong> <a href={offer.link} target="_blank" rel="noopener noreferrer">{offer.link}</a></p>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <AccordionButton edit onClick={() => editOffer(offer.id)}>
                      Редактировать
                    </AccordionButton>
                    <AccordionButton onClick={() => deleteOffer(offer.id)}>
                      Удалить
                    </AccordionButton>
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </div>
        )}

        {/* Loader for Mobile when loading */}
        {isLoading && (
          <table>
            <tbody>
              <tr>
                <LoaderTd colSpan="4">Загрузка...</LoaderTd>
              </tr>
            </tbody>
          </table>
        )}
      </ContainerWrapperLink>
    </MainContainer>
  );
};

export default MyLinktable;
