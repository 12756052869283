import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Image, LoaderIcon, Paperclip } from "lucide-react";
import IconButton from "../IconButton";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request, { base_url, file_url, storage_url } from "../../utils/request";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import Loadering from "../Loader/Loadering";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import toast from "react-hot-toast";
import { OggRecorder } from "../../assets/webpToOgg/OggRecorder";
const ChatContainer = styled.section`
  width: 100%;
  height: ${(props) => props.size - 80}px;
  display: flex;
  flex-direction: column;
`;

const ChatHeader = styled.div`
  height: 96px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const ChatHeaderAvatar = styled.div`
  width: 64px;
  height: 64px;
  background: #ffd5c3;
  border-radius: 32px;
  margin-right: 12px;
`;

const ChatHeaderName = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #171a1f;
  margin: 0;
`;

const ChatBody = styled.div`
  flex-grow: 1;
  background: #f8f9fa;
  overflow-y: auto;
  padding: 20px;
  max-height: 72vh;
`;

const MessageContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  transition: box-shadow 0.2s, opacity 0.2s, background-color 0.2s;
`;

const MessageContent = styled.div`
  background: ${(props) => (props.isUser ? "#f1f2fd" : "#ffffff")};
  border: 1px solid ${(props) => (props.isUser ? "#cacdf8" : "#f3f4f6")};
  border-radius: 4px;
  padding: 11px;
  max-width: 70%;
`;

const MessageText = styled.p`
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #171a1f;
  margin: 0;
`;

const MessageTime = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #6e7787;
  margin-top: 4px;
  display: block;
`;

const ChatInputArea = styled.div`
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  height: 35px;
  background: #f3f4f6;
  border: none;
  border-radius: 4px;
  padding: 0 12px;
  margin: 0 12px;
  font-family: "Lato", sans-serif;
  font-size: 14px;
`;

const SendButton = styled(IconButton)`
  width: 87px;
  height: 34px;
  background: #535ce8;
  color: #ffffff;
  border-radius: 4px;
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 9px;
`;

const AvatarIsUser = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 9px;
`;
const rotate = keyframes`
  to {
    transform: rotate(0.5turn);
  }
`;

const LoadIcon = styled.div`
  animation: ${rotate} 1s infinite;
`;
function Dialogue() {
  const { user } = SelectAuth();
  const { height } = useWindowSize();
  const { id } = useParams();
  const location = useLocation();

  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);
  const accountId = queryParams.get("account_id");

  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [chunk, setChunk] = useState(1);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [inputMessage, setInputMessage] = useState("");
  const [isAtBottom, setIsAtBottom] = useState(true);
  const fileInputRef = useRef(null);
  const chatBodyRef = useRef(null);
  const myId = Number(localStorage["id"]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsLoading(false);
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await request.get("/chat/info/" + id);
        setInfo(response?.data?.data[0]);
      } catch (err) {
        // Handle error
      }
    };
    fetchUser();
  }, [id]);

  useEffect(() => {
    // Fetch initial messages
    fetchMessages(1, true);
  }, [info]);
  const fetchMessages = async (chunkNumber = 1, initialLoad = false) => {
    if (!info?.id) return;

    try {
      const response = await request.get(
        `/chat/messages?chunk=${chunkNumber}&chat_id=${info?.id}`
      );
      const newMessages = response?.data?.data?.reverse();

      if (newMessages && newMessages.length > 0) {
        if (initialLoad) {
          setMessages(newMessages);
          setChunk(1);
          setHasMoreMessages(true);

          // Scroll to bottom after initial messages are loaded
          setTimeout(() => {
            if (chatBodyRef.current) {
              chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            }
          }, 0);
        } else {
          setMessages((prevMessages) => [...newMessages, ...prevMessages]);

          // Adjust scroll position to maintain position
          setTimeout(() => {
            if (chatBodyRef.current) {
              const scrollHeightAfter = chatBodyRef.current.scrollHeight;
              chatBodyRef.current.scrollTop =
                scrollHeightAfter - chatBodyRef.current.clientHeight;
            }
          }, 0);
        }
      } else {
        setHasMoreMessages(false);
      }
    } catch (err) {
      // Handle error
    }
  };

  const handleScroll = () => {
    if (chatBodyRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatBodyRef.current;
      if (scrollTop === 0 && hasMoreMessages && !isLoadingMore) {
        loadMoreMessages();
      }

      // Check if user is at bottom
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setIsAtBottom(true);
      } else {
        setIsAtBottom(false);
      }
    }
  };

  const loadMoreMessages = async () => {
    setIsLoadingMore(true);
    const currentScrollHeight = chatBodyRef.current.scrollHeight;

    const nextChunk = chunk + 1;

    try {
      const response = await request.get(
        `/chat/messages?chunk=${nextChunk}&chat_id=${info?.id}`
      );
      const newMessages = response?.data?.data?.reverse();

      if (newMessages && newMessages.length > 0) {
        setMessages((prevMessages) => [...newMessages, ...prevMessages]);
        setChunk(nextChunk);

        // Adjust scroll position to prevent jumpiness
        setTimeout(() => {
          if (chatBodyRef.current) {
            const newScrollHeight = chatBodyRef.current.scrollHeight;
            chatBodyRef.current.scrollTop =
              newScrollHeight - currentScrollHeight;
          }
        }, 0);
      } else {
        setHasMoreMessages(false);
      }
    } catch (err) {
      // Handle error
    } finally {
      setIsLoadingMore(false);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      fetchLatestMessages();
    }, 14000);

    return () => clearInterval(interval);
  }, [messages, info]);

  const fetchLatestMessages = async () => {
    if (!info?.id) return;
    try {
      const response = await request.get(
        `/chat/messages?chunk=1&chat_id=${info?.id}`
      );
      const newMessages = response?.data?.data?.reverse();
      setMessages(newMessages);
      setTimeout(() => {
        chatBodyRef.current.scrollTop = 999999;
      }, 400);
    } catch (err) {
      // Handle error
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage?.trim() !== "") {
      setInputMessage("");
      try {
        const token = localStorage.getItem("token");
        const formData = new FormData();

        formData.append("text", inputMessage);
        formData.append("chat_id", info?.chat?.id);
        formData.append("account_id", accountId);

        await axios.post(base_url + "/chat/message/send", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // After sending message, fetch latest messages
        fetchLatestMessages();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("token");
        const formData = new FormData();

        formData.append("chat_id", info?.chat?.id);
        formData.append("file", file);
        formData.append("account_id", accountId);

        await axios.post(base_url + "/chat/message/send", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // After sending file, fetch latest messages
        fetchLatestMessages();
        setIsLoading(false);
      } catch (error) {
        toast.error("Ошика!");
        setIsLoading(false);
      }
    }
  };
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      // echoCancellation: true,
      encodingType: "audio/ogg",
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const addAudioElement = async (blob) => {
    const audioFile = new File([blob], "audio_message.ogg", {
      type: "audio/ogg",
    });
    await sendAudioFile(audioFile);
  };

  const sendAudioFile = async (file) => {
    if (!file) return;

    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();

      formData.append("chat_id", info?.chat?.id);
      formData.append("account_id", accountId);
      formData.append("file", file);
      await axios.post(base_url + "/chat/message/send", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // After sending the file, fetch the latest messages
      fetchLatestMessages();
    } catch (error) {
      console.error("Error sending audio file:", error);
    }
  };
  
  return (
    <ChatContainer size={height}>
      {isLoading && <Loadering />}
      <ChatHeader>
        <ChatHeaderAvatar>
          <img
            width={"100%"}
            height={"100%"}
            style={{ borderRadius: 50 }}
            src={
              (info?.chat?.info?.photo?.path &&
                storage_url +
                  info?.chat?.info?.photo?.path +
                  "/" +
                  info?.chat?.info?.photo?.name) ||
              "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
            }
            alt="chat"
          />
        </ChatHeaderAvatar>
        <ChatHeaderName>
          {`${info?.chat?.info?.first_name || "Пользователь"} ${
            info?.chat?.info?.last_name || ""
          }`}
        </ChatHeaderName>
      </ChatHeader>
      <ChatBody ref={chatBodyRef} onScroll={handleScroll}>
        {isLoadingMore && (
          <div style={{ padding: 10, textAlign: "center" }}>
            <LoadIcon>
              <LoaderIcon />
            </LoadIcon>
          </div>
        )}
        {messages?.map((message, index) => (
          <MessageContainer
            key={message?.id}
            isUser={message?.user_id === myId}
            index={index}
          >
            {message?.user_id !== myId && (
              <AvatarIsUser
                src={
                  (info?.chat?.info?.photo?.path &&
                    storage_url +
                      info?.chat?.info?.photo?.path +
                      "/" +
                      info?.chat?.info?.photo?.name) ||
                  "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
                }
                alt="User avatar"
              />
            )}

            <MessageContent isUser={message?.user_id === myId}>
              {message?.photo && (
                <img
                  src={file_url + message?.photo}
                  alt={message?.photo}
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              )}
              {message?.document && (
                <a
                  href={file_url + message?.document}
                  alt={message?.document}
                  style={{ maxWidth: "100%", maxHeight: "200px", display: 'flex', alignItems: 'center', gap: 5, textDecoration: 'none', color: 'black' }}
                  target="_blank"
                >
                  <img
                    src="https://images.freeimages.com/fic/images/icons/2813/flat_jewels/512/file.png"
                    alt="file"
                    width={30}
                    height={30}
                  />
                  {message?.document.slice(
                    message?.document?.lastIndexOf("/") + 1
                  )}
                </a>
              )}
              {message?.voice && (
                <audio src={file_url + message?.voice} controls></audio>
              )}
              {message?.audio && (
                <audio src={file_url + message?.audio} controls></audio>
              )}
              {message?.video && (
                <video
                  src={file_url + message?.video}
                  controls
                  width={"100%"}
                  style={{
                    maxHeight: 400
                  }}
                ></video>
              )}
              {message?.text && (
                <MessageText
                  dangerouslySetInnerHTML={{ __html: message?.text }}
                ></MessageText>
              )}

              <MessageTime>{message?.created_at?.slice(11, 16)}</MessageTime>
            </MessageContent>
            {message?.user_id === myId && (
              <Avatar
                src={
                  "https://i0.wp.com/www.repol.copl.ulaval.ca/wp-content/uploads/2019/01/default-user-icon.jpg?ssl=1"
                }
                alt="User avatar"
              />
            )}
          </MessageContainer>
        ))}
      </ChatBody>
      {["owner"].includes(user?.role) && (
        <ChatInputArea>
          <IconButton onClick={() => fileInputRef.current.click()}>
            <Paperclip size={24} />
          </IconButton>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />

          <IconButton onClick={() => fileInputRef.current.click()}>
            <Image size={24} />
          </IconButton>
          {/* <AudioRecorder
            onRecordingComplete={(blob) => addAudioElement(blob)}
            recorderControls={recorderControls}
            // downloadOnSavePress={true}
            // downloadFileExtension="wav"
            showVisualizer={true}
          /> */}
          <OggRecorder chat_id={info?.chat?.id} accountId={accountId} fetchLatestMessages={fetchLatestMessages}/>
          <ChatInput
            placeholder="Type a message..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
          />

          <SendButton onClick={handleSendMessage}>Отправить</SendButton>
        </ChatInputArea>
      )}
    </ChatContainer>
  );
}

export default Dialogue;
