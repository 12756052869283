import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import OfferLink from "./OfferLink";
import MyLinktable from "./MyLinkTable";
import { useWindowSize } from "../../utils";
import { SelectAuth } from "../../redux/selectors";
import request from "../../utils/request"; // Import axios request module
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // For accordion icons

// Styled Components

const MainContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  /* box-shadow: 0 3px 6px 0 rgba(18, 15, 40, 0.12); */
  font-family: "Lato", sans-serif;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    height: calc(100vh - 65px);
    overflow: auto;
  }
`;

const TabContainer = styled.div`
  display: flex;
  margin: 29px 0 0 49px;

  @media (max-width: 768px) {
    margin: 0;
    overflow: auto;
    width: 95vw;
    padding-right: 20px;
  }
`;

const Tab = styled.button`
  padding: 10px 20px;
  background: ${(props) => (props.active ? "#cacdf8" : "#ffffff")};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: ${(props) => (props.active ? "#535ce8" : "#565e6c")};
  font-weight: ${(props) => (props.active ? "700" : "400")};
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    white-space: nowrap;
  }
`;

const Title = styled.h2`
  font-family: "Outfit", sans-serif;
  font-size: 32px;
  font-weight: 800;
  color: #171a1f;
  margin: 32px 0 20px 52px;

  @media (max-width: 768px) {
    margin: 20px 0 10px 0;
    font-size: 24px;
    text-align: center;
  }
`;

const Form = styled.form`
  margin-left: 52px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const Label = styled.label`
  display: block;
  color: #424955;
  font-weight: 700;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Input = styled.input`
  width: 526px;
  height: 35px;
  background: #f3f4f6;
  border: 1px solid ${(props) => (props.error ? "red" : "#f3f4f6")};
  border-radius: 4px;
  padding: 0 12px;
  font-size: 14px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Select = styled.select`
  width: 526px;
  height: 35px;
  background: #f3f4f6;
  border: 1px solid ${(props) => (props.error ? "red" : "#f3f4f6")};
  border-radius: 4px;
  padding: 0 12px;
  font-size: 14px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SubmitButton = styled.button`
  width: 120px;
  height: 36px;
  background: #535ce8;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 10px;

  &:disabled {
    background: #cccccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Table = styled.table`
  width: 100%;
  min-width: 1200px;
  margin: 20px 0 0 53px;
  border-collapse: collapse;
  margin-bottom: 5rem;

  @media (max-width: 1200px) {
    min-width: 1000px;
  }

  @media (max-width: 992px) {
    min-width: 800px;
  }

  @media (max-width: 768px) {
    display: none; /* Hide table on mobile */
  }
`;

const Th = styled.th`
  background: #fafafb;
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
  white-space: nowrap;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 10px 8px;
    font-size: 12px;
  }
`;

const Td = styled.td`
  border: 1px solid #f3f4f6;
  padding: 15px 12px;
  white-space: nowrap;
  font-size: 14px;

  @media (max-width: 768px) {
    padding: 10px 8px;
    font-size: 12px;
  }
`;

const Tr = styled.tr`
  white-space: nowrap;
  &:hover {
    background-color: #fff4f0;
  }

  @media (max-width: 768px) {
    &:hover {
      background-color: #f9f9f9;
    }
  }
`;

const ActionButton = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
  background: ${(props) => (props.edit ? "#379ae6" : "#c02a2a")};
  font-size: 12px;

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 10px;
  }
`;

const ContainerWrapperLink = styled.div`
  overflow-y: scroll;
  height: 100vh;
  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

  @media (max-width: 768px) {
  overflow-y: hidden;
  height: auto;
  }
`;

const ScrollableTableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  max-width: ${(props) => props.size - 100}px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const TableContainer = styled.div`
  margin-bottom: 32px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Accordion Styled Components

const AccordionContainer = styled.div`
  display: none; /* Hidden on desktop */

  @media (max-width: 768px) {
    display: block;
    margin: 20px 0 0 0;
  }
`;

const AccordionItem = styled.div`
  border: 1px solid #f3f4f6;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
`;

const AccordionHeader = styled.div`
  background: #fafafb;
  padding: 15px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const AccordionTitle = styled.div`
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  color: #565e6c;
  font-size: 14px;
`;

const AccordionIcon = styled.div`
  font-size: 20px;
  color: #565e6c;
`;

const AccordionContent = styled.div`
  padding: 15px 12px;
  background: #ffffff;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const ContentRow = styled.div`
  margin-bottom: 10px;
`;

const ContentLabel = styled.span`
  font-weight: 700;
  color: #424955;
`;

const ContentValue = styled.span`
  margin-left: 5px;
  color: #565e6c;
`;

// Add Offer Component

const AddOfferComponent = ({
  register,
  handleSubmit,
  errors,
  onSubmit,
  editIndex,
  isLoading,
  control,
}) => {
  return (
    <>
      <Title>
        {editIndex !== null ? "Редактировать оффер" : "Добавить оффер"}
      </Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>Название компании</Label>
          <Input
            {...register("company_name", { required: true })}
            error={errors.company_name}
            placeholder="Введите название компании"
          />
        </FormGroup>
        <FormGroup>
          <Label>Название оффера</Label>
          <Input
            {...register("offer_name", { required: true })}
            error={errors.offer_name}
            placeholder="Введите название оффера"
          />
        </FormGroup>

        <FormGroup>
          <Label>Ставка</Label>
          <Input
            type="number"
            {...register("rate", { required: true })}
            error={errors.rate}
            placeholder="Введите ставку"
          />
        </FormGroup>
        <FormGroup>
          <Label>Валюта</Label>
          <Select
            {...register("currency", { required: true })}
            error={errors.currency}
          >
            <option value="$">$</option>
            <option value="€">€</option>
            <option value="₽">₽</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <Label>Геопозиция</Label>
          <Input
            {...register("geo", { required: true })}
            error={errors.geo}
            placeholder="Введите геопозицию"
          />
        </FormGroup>
        <FormGroup>
          <Label>Краткое описание</Label>
          <Input
            {...register("comment", { required: true })}
            error={errors.comment}
            placeholder="Введите краткое описание"
          />
        </FormGroup>
        <SubmitButton type="submit" disabled={isLoading ? true : false}>
          {isLoading
            ? "Загрузка..."
            : editIndex !== null
            ? "Обновить"
            : "Добавить"}
        </SubmitButton>
      </Form>
    </>
  );
};

// My Links Component

const MyLinksComponent = () => {
  return (
    <>
      <Title>Мои ссылки</Title>
      <MyLinktable />
    </>
  );
};

// OfferManagement Component

const OfferManagement = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { user } = SelectAuth();
  const { width } = useWindowSize();
  const [activeTab, setActiveTab] = useState(
    user?.role === "owner" ? "addOffer" : "generateLink"
  );
  const [offers, setOffers] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState({}); // To manage accordion state

  // Fetch offers from the API when the component mounts
  useEffect(() => {
    const fetchOffers = async () => {
      setIsLoading(true);
      try {
        const response = await request.get("/offers");
        setOffers(response.data);
      } catch (error) {
        console.error("Error fetching offers:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOffers();
  }, []);

  // Handle form submission (add/update offer)
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editIndex !== null) {
        // Update offer via API
        const response = await request.put(
          `/offers/${offers[editIndex].id}`,
          data
        );
        const newOffers = [...offers];
        newOffers[editIndex] = response.data;
        setOffers(newOffers);
        setEditIndex(null);
      } else {
        // Add new offer via API
        const response = await request.post("/offers", data);
        setOffers([...offers, response.data]);
      }

      reset({
        company_name: "",
        offer_name: "",
        rate: "",
        currency: "₽",
        geo: "",
        comment: "",
      });
    } catch (error) {
      console.error("Error submitting offer:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete offer from the API
  const deleteOffer = async (index) => {
    try {
      const offerToDelete = offers[index];
      await request.delete(`/offers/${offerToDelete.id}`);
      setOffers(offers.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting offer:", error);
    }
  };

  // Edit offer
  const editOffer = (index, offer) => {
    setEditIndex(index);
    setActiveTab("addOffer");
    reset(offer);
    // Scroll to the form when editing
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Toggle accordion
  const toggleAccordion = (index) => {
    setAccordionOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <MainContainer>
      <ContainerWrapperLink>
        <TabContainer>
          {["owner"].includes(user?.role) && (
            <Tab
              active={activeTab === "addOffer"}
              onClick={() => setActiveTab("addOffer")}
            >
              Добавление оффера
            </Tab>
          )}
          <Tab
            active={activeTab === "generateLink"}
            onClick={() => setActiveTab("generateLink")}
          >
            Генерация ссылки
          </Tab>
          <Tab
            active={activeTab === "myLinks"}
            onClick={() => setActiveTab("myLinks")}
          >
            Мои ссылки
          </Tab>
        </TabContainer>

        {activeTab === "addOffer" && (
          <AddOfferComponent
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            onSubmit={onSubmit}
            editIndex={editIndex}
            isLoading={isLoading}
          />
        )}
        {activeTab === "generateLink" && <OfferLink />}
        {activeTab === "myLinks" && <MyLinksComponent />}

        {activeTab === "addOffer" && (
          <>
            {/* Desktop Table */}
            <TableContainer>
              <Title>Смотреть Оффер</Title>
              <ScrollableTableWrapper size={width}>
                {isLoading ? (
                  <p>Loading...</p>
                ) : (
                  <Table>
                    <thead>
                      <tr>
                        <Th>Название компании</Th>
                        <Th>Название оффера</Th>
                        <Th>Ставка</Th>
                        <Th>Геопозиция</Th>
                        <Th>Описание</Th>
                        <Th>Действия</Th>
                      </tr>
                    </thead>
                    <tbody>
                      {offers.map((offer, index) => (
                        <Tr key={offer.id}>
                          <Td>{offer.company_name}</Td>
                          <Td>{offer.offer_name}</Td>
                          <Td>{`${offer.rate} ${offer.currency}`}</Td>
                          <Td>{offer.geo}</Td>
                          <Td>{offer.comment}</Td>
                          <Td>
                            <ActionButton
                              edit
                              onClick={() => editOffer(index, offer)}
                            >
                              Редактировать
                            </ActionButton>
                            <ActionButton
                              onClick={() =>
                                window.confirm("Delete this element.") &&
                                deleteOffer(index)
                              }
                            >
                              Удалить
                            </ActionButton>
                          </Td>
                        </Tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </ScrollableTableWrapper>
            </TableContainer>

            {/* Mobile Accordion */}
            <AccordionContainer>
              <Title>Смотреть Оффер</Title>
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                offers.map((offer, index) => (
                  <AccordionItem key={offer.id}>
                    <AccordionHeader onClick={() => toggleAccordion(index)}>
                      <AccordionTitle>
                        {offer.company_name} - {offer.offer_name}
                      </AccordionTitle>
                      <AccordionIcon>
                        {accordionOpen[index] ? (
                          <FiChevronUp />
                        ) : (
                          <FiChevronDown />
                        )}
                      </AccordionIcon>
                    </AccordionHeader>
                    <AccordionContent isOpen={accordionOpen[index]}>
                      <ContentRow>
                        <ContentLabel>Ставка:</ContentLabel>
                        <ContentValue>{`${offer.rate} ${offer.currency}`}</ContentValue>
                      </ContentRow>
                      <ContentRow>
                        <ContentLabel>Геопозиция:</ContentLabel>
                        <ContentValue>{offer.geo}</ContentValue>
                      </ContentRow>
                      <ContentRow>
                        <ContentLabel>Описание:</ContentLabel>
                        <ContentValue>{offer.comment}</ContentValue>
                      </ContentRow>
                      <ContentRow>
                        <ActionButton
                          edit
                          onClick={() => editOffer(index, offer)}
                        >
                          Редактировать
                        </ActionButton>
                        <ActionButton
                          onClick={() =>
                            window.confirm("Delete this element.") &&
                            deleteOffer(index)
                          }
                        >
                          Удалить
                        </ActionButton>
                      </ContentRow>
                    </AccordionContent>
                  </AccordionItem>
                ))
              )}
            </AccordionContainer>
          </>
        )}
      </ContainerWrapperLink>
    </MainContainer>
  );
};

export default OfferManagement;
