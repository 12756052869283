import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import TelegramAuthComponent from "./TelegramAuthComponent";
import LoginChart from "../../assets/image/ChartLogin.png";
import LoginChartLog from "../../assets/image/ChartLog.png";
import { login } from "../../redux/reducers/AuthReducer";
import request from "../../utils/request";
import toast from "react-hot-toast";
const MainContainer = styled.div`
  position: relative;
  height: 100vh;
  margin: 0 auto;
  background: #ffffff;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(18, 15, 40, 0.12);
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, sans-serif;

  @media (max-width: 768px) {
    /* height: auto; */
    display: flex;
    align-items: center;
  }
`;

const DashboardContainer = styled.div`
  position: absolute;
  width: 900px;
  top: -0.5px;
  right: 0;
  bottom: 0.5px;
  background: #dee0fa;

  @media (max-width: 1024px) {
    width: 100%;
    position: static;
    padding: 20px;
  }

  @media (max-width: 768px) {
    display: none; /* Hide the second section */
  }
`;

const ChartContainer = styled.div`
  position: relative;
  width: 460px;
  height: 326px;
  margin: 100px 0 0 201px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);

  @media (max-width: 1024px) {
    width: 100%;
    margin: 20px 0;
  }
`;

const ChartTitle = styled.span`
  display: block;
  position: relative;
  height: 22px;
  margin: 24px 0 0 24px;
  color: #424955;
  font-size: 14px;
  font-weight: 400;
  line-height: 45px;
`;

const PieChart = styled.div`
  position: relative;
  width: 326px;
  height: 220px;
  margin: 36px 0 0 67px;

  @media (max-width: 768px) {
    margin: 20px auto;
    width: 90%;
    height: auto;
  }
`;

const Chart = styled.div`
  position: absolute;
  width: 62.27%;
  height: 92.27%;
  top: 3.86%;
  left: 0;
  background: url(${(props) => props.chartImage}) no-repeat center;
  background-size: 100% 100%;
`;

const LegendContainer = styled.div`
  position: absolute;
  width: 94px;
  height: 60px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);

  @media (max-width: 768px) {
    position: static;
    transform: none;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }
`;

const LegendItems = styled.div`
  position: absolute;
  width: 79px;
  height: 60px;
  top: 50%;
  left: 50%;
  transform: translate(-39.24%, -50%);

  @media (max-width: 768px) {
    position: static;
    transform: none;
  }
`;

const LegendItem = styled.span`
  display: block;
  position: relative;
  height: 22px;
  margin: ${(props) => props.margin || "0"};
  color: #171a1f;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  width: 120px;
`;

const LegendMarkers = styled.div`
  position: absolute;
  width: 12px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-391.67%, -50%);
`;

const LegendMarker = styled.div`
  position: relative;
  width: 12px;
  height: 12px;
  margin: ${(props) => props.margin || "0"};
  background: url(${(props) => props.markerImage}) no-repeat center;
  background-size: cover;
  border-radius: 50%;
`;

const LoginContainer = styled.div`
  position: absolute;
  top: 0;
  right: 900px;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0 0 1px 0 rgba(23, 26, 31, 0.15);

  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    box-shadow: none;
    right: 0; /* Ensure it takes the full space */
  }

  @media (max-width: 768px) {
    width: 100%;
    right: 0;
    left: 0;
  }
`;

const Logo = styled.span`
  display: block;
  position: relative;
  height: 30px;
  margin: 24px 0 0 53px;
  color: #424955;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;

  @media (max-width: 768px) {
    text-align: center;
    margin: 20px auto;
  }
`;

const LoginForm = styled.div`
  position: relative;
  width: 401px;
  height: 234px;
  margin: 278.5px 0 0 160px;

  @media (max-width: 768px) {
    margin: 50px auto;
    width: 90%;
    height: auto;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 400px;
  height: 71px;
  margin: ${(props) => props.margin || "0"} 0 0 1px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InputLabel = styled.span`
  display: block;
  position: relative;
  height: 26px;
  color: #424955;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
`;

const InputField = styled.input`
  width: 399px;
  height: 43px;
  margin: 1px 0 0 0;
  background: transparent;
  border: 1px solid #bcc1ca;
  border-radius: 6px;
  padding: 0 16px;
  font-size: 16px;

  &::placeholder {
    color: #bcc1ca;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LoginButton = styled.button`
  position: relative;
  width: 400px;
  height: 44px;
  margin: 24px 0 0 0;
  cursor: pointer;
  background: #535ce8;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #bcc1ca;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Loader = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.span`
  color: #ff0000;
  font-size: 12px;
  margin-top: 4px;
`;

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const OpenDashboard = () => {
    navigate("/", { replace: true });
  };
  const location = useLocation();

  // Create a new URLSearchParams object using the search property of the location
  const queryParams = new URLSearchParams(location.search);

  // Get the 'id' parameter from the query string
  const idParam = queryParams.get("id");
  // New API login function
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await request.post("/auth/login", data); // Make API call
      if (response.status === 200) {
        const userData = response.data;
        localStorage.setItem("token", userData?.token);
        toast.success("Login successful:", userData);
        if (userData.telergam_status == 0) {
          setIsLogin(true); // Update login state
        } else {
          OpenDashboard();
        }
        dispatch(
          login({
            username: userData.user?.email,
            role: userData.role,
          })
        );
      } else {
        toast.error("Login failed: Invalid credentials");
      }
    } catch (error) {
      toast.error("Login failed: Invalid credentials");
    } finally {
      setIsLoading(false); // Stop the loader
    }
  };

  return (
    <MainContainer>
      <DashboardContainer>
        <ChartContainer>
          <ChartTitle>UNSOLVED TICKETS BY STATUS</ChartTitle>
          <PieChart>
            <Chart chartImage={LoginChart} />
            <LegendContainer>
              <LegendItems>
                <LegendItem>Open tickets</LegendItem>
                <LegendItem margin="16px 0 0 0">New tickets</LegendItem>
              </LegendItems>
              <LegendMarkers>
                <LegendMarker markerImage={LoginChart} />
                <LegendMarker margin="26px 0 0 0" markerImage={LoginChart} />
              </LegendMarkers>
            </LegendContainer>
          </PieChart>
        </ChartContainer>
        <ChartContainer>
          <ChartTitle>UNSOLVED TICKETS BY ASSIGNMENT STATUS</ChartTitle>
          <PieChart>
            <Chart chartImage={LoginChartLog} />
            <LegendContainer>
              <LegendItems>
                <LegendItem>Assigned tickets</LegendItem>
                <LegendItem margin="16px 0 0 0">Unassigned tickets</LegendItem>
              </LegendItems>
              <LegendMarkers>
                <LegendMarker markerImage={LoginChart} />
                <LegendMarker margin="26px 0 0 0" markerImage={LoginChartLog} />
              </LegendMarkers>
            </LegendContainer>
          </PieChart>
        </ChartContainer>
      </DashboardContainer>
      <LoginContainer>
        <Logo>CRM</Logo>
        <LoginForm>
          {isLogin || idParam == 1 ? (
            <TelegramAuthComponent openDashboard={OpenDashboard} />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputContainer>
                <InputLabel>Логин</InputLabel>
                <InputField
                  placeholder="Email"
                  {...register("email", {
                    required: "Требуется электронная почта",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Неверный адрес электронной почты",
                    },
                  })}
                />
                {errors.email && (
                  <ErrorMessage>{errors.email.message}</ErrorMessage>
                )}
              </InputContainer>
              <InputContainer margin="24px">
                <InputLabel>Пароль</InputLabel>
                <InputField
                  type="password"
                  placeholder="Не менее 8 символов"
                  {...register("password", {
                    required: "Требуется ввести пароль",
                    minLength: {
                      value: 8,
                      message: "Пароль должен содержать не менее 8 символов",
                    },
                  })}
                />
                {errors.password && (
                  <ErrorMessage>{errors.password.message}</ErrorMessage>
                )}
              </InputContainer>
              <LoginButton type="submit" disabled={!isValid || isLoading}>
                {isLoading ? "" : "Войти"}
                {isLoading && <Loader />}
              </LoginButton>
            </form>
          )}
        </LoginForm>
      </LoginContainer>
    </MainContainer>
  );
};

export default LoginPage;
